.root {
  flex-grow: 1;
  display: flex;
}

.p {
  background-color: #282c34;
  color: white;
  text-decoration-color: aqua;
  padding: 40px;
  font-family: Arial;
  text-align: center;
}

.menuButton {
  margin-right: 150px,
}

.title {
  flex-grow: 1;
}

.ToolBar {
  background-color: #282c34;
}

.footer {
  padding: 10px;
  margin-top: auto;
  background-color: #282c34;
}

.footer-root {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem;   
}

.paper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: #282c34;
}

.form {
  width: 375px;
}

.submit {
  padding: 10px;
  margin-top: 10px;
}

.backdrop {
  z-index: 1;
  color: #282c34;
}

.MuiButton-colorInherit {
  background-color: white;
}